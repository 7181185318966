// ** Type import
import { VerticalNavItemsType } from 'src/@core/layouts/types'

const navigation = (): VerticalNavItemsType => {
  return [
    {
      subject: 'dashboard',
      sectionTitle: 'CHATTERgo'
    },
    {
      title: 'Dashboard',
      subject: 'dashboard',
      icon: 'mdi:home-outline',
      path: '/welcome'
    },
    {
      subject: 'chat',
      sectionTitle: 'Internal Assist'
    },
    {
      title: 'Chat',
      subject: 'chat',
      icon: 'mdi:message-outline',
      path: '/apps/chat'
    },
    {
      title: 'History',
      subject: 'chat',
      icon: 'mdi:history',
      path: '/apps/chat/history'
    },
    {
      title: 'Prompts',
      subject: 'setup',
      icon: 'mdi:clipboard-edit-outline',
      path: '/apps/chat/prompt'
    },
    {
      sectionTitle: 'Manage Data'
    },
    {
      title: 'Content',
      subject: 'content',
      icon: 'mdi:file-code-outline',
      path: '/apps/data'
    },
    {
      title: 'Products',
      subject: 'product',
      icon: 'mdi:shopping-search-outline',
      path: '/apps/products'
    },
    {
      sectionTitle: 'Public Widget'
    },
    {
      title: 'Widget',
      subject: 'widget',
      icon: 'mdi:chat-processing-outline',
      path: '/apps/public/widget'
    },
    {
      title: 'History',
      subject: 'widget',
      icon: 'mdi:history',
      path: '/apps/public/history'
    },
    {
      subject: 'setup',
      sectionTitle: 'Integration'
    },
    {
      title: 'Plugins',
      subject: 'setup',
      icon: 'mdi:connection',
      path: '/apps/data/plugin'
    },
    {
      title: 'Channels',
      subject: 'setup',
      icon: 'mdi:shopping-outline',
      path: '/apps/products/setup'
    }
  ]
}

export default navigation
