// ** React Imports
import { ReactNode, useEffect, useState } from 'react'
import Cookies from 'js-cookie'

// ** Next Import
import { useRouter } from 'next/router'

// ** Types
import type { ACLObj, AppAbility } from 'src/configs/acl'

// ** Context Imports
import { AbilityContext } from 'src/layouts/components/acl/Can'

// ** Config Import
import { buildAbilityFor } from 'src/configs/acl'

// ** Component Import
import NotAuthorized from 'src/pages/401'
import Spinner from 'src/@core/components/spinner'
import BlankLayout from 'src/@core/layouts/BlankLayout'

// ** Hooks
// import { useAuth } from 'src/hooks/useAuth'

import { useUser } from 'src/utils/useUser'

// ** Util Import
import getHomeRoute from 'src/layouts/components/acl/getHomeRoute'

interface AclGuardProps {
  children: ReactNode
  authGuard?: boolean
  guestGuard?: boolean
  aclAbilities: ACLObj
}

const AclGuard = (props: AclGuardProps) => {
  // ** Props
  const { aclAbilities, children, guestGuard = false, authGuard = true } = props

  // ** Hooks
  const auth = useUser()
  const router = useRouter()

  const [ability, setAbility] = useState<AppAbility | null>(null)

  const [userDetailsLoaded, setUserDetailsLoaded] = useState(false)
  const [guestLoaded, setGuestLoaded] = useState(false)

  const [authChecked, setAuthChecked] = useState(false)

  // ** Vars
  // let ability: AppAbility

  useEffect(() => {
    //    console.log('At UserAclGuard useEffect')
    //    console.log('Current page', router.pathname)
    //    console.log('Auth User: ', auth.user)
    //    console.log('Auth User Detail: ', auth.userDetails)
    if (auth.user && auth.userDetails) {
      setUserDetailsLoaded(true)

      if (auth.userDetails.role) {
        console.log('Get Ability, my role is: ', auth.userDetails.role)
        const newAbility = buildAbilityFor(auth.userDetails.role, aclAbilities.subject)
        setAbility(newAbility)

        if (!guestGuard && router.route === '/') {
          const homeRoute = getHomeRoute(auth.userDetails.role)
          router.replace(homeRoute)
        }
      }

      if (
        !auth.userDetails.organization &&
        router.pathname !== '/apps/oauth/shopify_organization' &&
        router.pathname !== '/apps/oauth/shopify_organization/' &&
        router.pathname !== '/apps/public' &&
        router.pathname !== '/apps/public/' &&
        router.pathname !== '/apps/organizations' &&
        router.pathname !== '/apps/organizations/' &&
        router.pathname !== '/apps/organizations/invite' &&
        router.pathname !== '/apps/organizations/invite/'
      ) {
        //        alert('replace shopify org to create org at ' + router.pathname)
        if (Cookies.get('shopify_shopCode')) {
          router.replace('/apps/oauth/shopify_organization/')
        } else {
          router.replace('/apps/organizations/')
        }
      }
    } else if (auth.user === null) {
      setGuestLoaded(true)
    }

    if (!auth.isLoading) {
      setAuthChecked(true)
    }
  }, [auth.user, auth.userDetails, auth.isLoading, guestGuard, router, aclAbilities.subject])

  // If guest guard or no guard is true or any error page
  if (guestGuard || router.route === '/404' || router.route === '/500' || !authGuard) {
    // If user is logged in and his ability is built
    if (auth.user && ability) {
      console.log('user is logged in and his ability is built:', ability)
      return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>
    } else {
      // If user is not logged in (render pages like login, register etc..)
      console.log('user is not logged in (render pages like login, register etc..)')
      return <>{children}</>
    }
  }

  if (!userDetailsLoaded && !guestLoaded) {
    console.log('spinning due to !userDetailsLoaded && !guestLoaded')
    return <Spinner />
  }

  if (!auth.user && router.route !== '/login') {
    console.log('spinning due to !auth.user && router.route !== login')
    router.replace('/login')
    return <Spinner />
  }

  if (ability && auth.user && ability.can(aclAbilities.action, aclAbilities.subject)) {
    return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>
  }

  // console.log('ACL not authorized')
  // console.log('ability: ', ability)
  // console.log('auth.user: ', auth.user)
  if (ability) {
    console.log('ability.can', ability.can(aclAbilities.action, aclAbilities.subject))
  }
  // Render Not Authorized component if the current user has limited access
  return (
    <BlankLayout>
      <NotAuthorized />
    </BlankLayout>
  )
}

export default AclGuard
