import React, { useState, useEffect, CSSProperties } from 'react'

interface Settings {
  apiKey: string
  chatBoxDesktopWidth: string
  chatBoxMobileWidth: string
  chatBoxHeight: string
  chatBoxLogoWidth: string
  chatBoxLogoHeight: string
  chatBoxLogoPath: string
  popupWidth: string
  popupHeight: string
  popupPosition: { top: string; left: string; bottom: string; right: string }
}

const Chattergo: React.FC = () => {
  const [showChat, setShowChat] = useState<boolean>(false)
  const [width, setWidth] = useState<number>(window.innerWidth)

  // Listen for window resize events to adjust width
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const toggleChat = (): void => {
    setShowChat(!showChat)
  }

  const setting: Settings = {
    apiKey: '301d7d59-a8c7-465e-a34e-c132eeaccd2c',
    chatBoxDesktopWidth: '800px',
    chatBoxMobileWidth: '450px',
    chatBoxHeight: '80vh',
    chatBoxLogoWidth: '55px',
    chatBoxLogoHeight: '55px',
    chatBoxLogoPath: 'https://app.chattergo.com/images/launcher/chattergo-bot-avatar.png',
    popupWidth: '400px',
    popupHeight: '400px',
    popupPosition: {
      top: 'unset',
      left: 'unset',
      bottom: '20px',
      right: '20px'
    }
  }

  const chatBoxWidth = width > 1024 ? setting.chatBoxDesktopWidth : setting.chatBoxMobileWidth

  // Styles for our components.
  const styles = {
    fixedDiv: (setting: Settings): CSSProperties => ({
      position: 'fixed',
      bottom: setting.popupPosition.bottom,
      right: setting.popupPosition.right,
      top: setting.popupPosition.top,
      left: setting.popupPosition.left,
      cursor: 'pointer',
      zIndex: 50
    }),
    image: (setting: Settings): CSSProperties => ({
      width: setting.chatBoxLogoWidth,
      height: setting.chatBoxLogoHeight,
      borderRadius: '50%'
    }),
    chatBoxDiv: (setting: Settings, showChat: boolean): CSSProperties => ({
      position: 'absolute',
      bottom: '120%',
      right: '0',
      width: chatBoxWidth,
      height: setting.chatBoxHeight,
      zIndex: 40,
      transition: 'transform 0.3s ease-in-out',
      transformOrigin: 'bottom right',
      transform: showChat ? 'scale(1)' : 'scale(0)'
    }),
    iframe: {
      width: '100%',
      height: '100%',
      border: 'none', // Remove the border
      backgroundColor: 'transparent' // This line sets the background to transparent
    } as CSSProperties
  }

  return (
    <div id='chatterGo' style={styles.fixedDiv(setting)} onClick={toggleChat}>
      <img src={setting.chatBoxLogoPath} style={styles.image(setting)} alt='Chat Logo' />
      {showChat && (
        <div id='chatBoxDiv' style={styles.chatBoxDiv(setting, showChat)}>
          <iframe src={`https://app.chattergo.com/apps/public/?apikey=${setting.apiKey}`} style={styles.iframe} />
        </div>
      )}
    </div>
  )
}

export default Chattergo
