// ** React Imports
import { ReactNode, ReactElement, useEffect } from 'react'

// ** Next Import
import { useRouter } from 'next/router'

// ** Hooks Import
// import { useAuth } from 'src/hooks/useAuth'

import { useUser } from 'src/utils/useUser'

interface GuestGuardProps {
  children: ReactNode
  fallback: ReactElement | null
}

const GuestGuard = (props: GuestGuardProps) => {
  const { children, fallback } = props
  const auth = useUser()
  const router = useRouter()

  useEffect(() => {
    console.log('At GuestGuard')
    console.log('Current page', router.pathname)
    console.log('Auth User: ', auth.user)

    if (!router.isReady) {
      console.log('return not ready at GuestGuard')
      return
    }

    if (auth.user && router.pathname !== '/apps/public' && router.pathname !== '/apps/public/') {
      console.log('got auth, redirect at GuestGuard')
      if (router.pathname === '/apps/oauth/magento_login' || router.pathname === '/apps/oauth/login/') {
        router.replace('/apps/oauth/magento_request')
      } else if (
        router.pathname === '/apps/oauth/shopify_redirect' ||
        router.pathname === '/apps/oauth/shopify_redirect/'
      ) {
        router.replace('/apps/oauth/shopify_organization')
      } else if (router.pathname === '/register') {
        router.replace('/welcome/')
      } else {
        router.replace('/')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.route, auth.user, auth.isLoading])

  if (auth.isLoading) {
    console.log('fallback at GuestGuard')
    console.log('auth.isLoading: ', auth.isLoading)
    console.log('auth.user: ', auth.user)
    return fallback
  }

  console.log('return children at GuestGuard')

  return <>{children}</>
}

export default GuestGuard
