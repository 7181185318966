// ** React Imports
import { ReactNode, ReactElement, useEffect } from 'react'

// ** Next Import
import { useRouter } from 'next/router'

// ** Hooks Import
// import { useAuth } from 'src/hooks/useAuth'

import { useUser } from 'src/utils/useUser'


interface AuthGuardProps {
  children: ReactNode
  fallback: ReactElement | null
}

const AuthGuard = (props: AuthGuardProps) => {
  const { children, fallback } = props
  const auth = useUser()
  const router = useRouter()

  useEffect(
    () => {
      console.log('At AuthGuard useEffect')
      console.log('Current page', router.pathname)
      console.log('Auth User: ', auth.user)

      if (!router.isReady || auth.isLoading) {
        console.log('router not ready at AuthGuard or user data is still loading')
        return
      }

      if (auth.user === null && !window.localStorage.getItem('userData')) {

        console.log('user is null and no window.localStorage at AuthGuard')
        if (router.asPath !== '/') {
          router.replace({
            pathname: '/login',
            query: { returnUrl: router.asPath }
          })
        } else {
          console.log('now going to login')
          router.replace('/login')
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [auth.user, router.route]
  )

  if (auth.isLoading) {
    console.log('return fallback at AuthGuard')
    console.log('At AuthGuard auth.isLoading: ', auth.isLoading)
    console.log('At AuthGuard auth.user: ', auth.user)
    return fallback
  }

  console.log('At AuthGuard auth.isLoading: ', auth.isLoading)
  console.log('At AuthGuard auth.user', auth.user)
  console.log('return children at AuthGuard')
  return <>{children}</>
}

export default AuthGuard
